import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.f} alt="f letter"/>
   </div>

   <div className="app__aboutus-content flex__center">
     <div className="app__aboutus-content_about">
       <h1 className="headtext__cormorant">About Us</h1>
       {/* <img src={images.spoon} alt="about_spoon" className="spoon_img"/> */}
       <p className="p__opensans">Lorem Ipsum Dolor Sit Amet,</p>
       <p className="p__opensans"> Consectetur Adipiscing Elit.</p>
       <p className="p__opensans">Quis Pharetra Adipiscing Ultrices Vulputate Posuere Tristique.</p>
       <p className="p__opensans">In Sed Odio Nec Aliquet Eu Proin Mauris Et.</p>
       <button type="button" className="custom__button">Expand</button>
     </div>

     <div className="app__aboutus-content_knife flex__center">
       <img src={images.knife} alt="about_knife"/>

     <div className="app__aboutus-content_history">
       <h1 className="headtext__cormorant">our history</h1>
      {/* <img src={images.spoon} alt="about_spoon" className="spoon_img"/> */}
       <p className="p__opensans">Lorem Ipsum Dolor Sit Amet,</p>
       <p className="p__opensans">Consectetur Adipiscing Elit.</p>
       <p className="p__opensans">Quis Pharetra Adipiscing Ultrices Vulputate Posuere Tristique.</p>
       <p className="p__opensans">In Sed Odio Nec Aliquet Eu Proin Mauris Et.</p>
       <button type="button" className="custom__button">Expand</button>
     </div>

     </div>

   </div>
  </div>
);

export default AboutUs;
